import { Button } from 'ui';
import { useTranslationScope } from '~/localization/use-translation-scope';
import { useNavigate } from '@remix-run/react';

export function ForbiddenError({ message }: { message?: string }) {
  const { t } = useTranslationScope('error-pages');
  const navigate = useNavigate();

  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="flex flex-col items-center text-center">
        <h4 className="text-h4">403</h4>
        <h1 className="text-h1">{t('error-pages:403-forbidden')}</h1>
        {message && <p className="text-gray-6 text-body my-4">{message}</p>}

        <div className="flex items-center gap-4">
          <Button onClick={() => navigate(-1)} id="error-page-go-back">
            {t('error-pages:go-back')}
          </Button>

          <Button id="error-page-go-to-moodys-home" href="https://moodys.com">
            {t('error-pages:go-to-moodys-home')}
          </Button>
        </div>
      </div>
    </div>
  );
}
